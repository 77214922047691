import { useQuery } from 'react-query';
import api from '../service/api';

// ----------------------------------------------------------------------
const oneHour = 1000 * 60 * 60 * 1
let timer = oneHour;
let refreshInterval;
// eslint-disable-next-line consistent-return
async function requestTime() {
  try {
    const response = await api.get(`/settings/login`);
    const times = response.data.data.settings.timeTokenExpires;
    if (times !== null && times !== undefined) {
      timer = 1000 * 60 * 60 * times;
      timer -= 1000 * 60 * 5;
    } else {
      timer = 1000 * 60 * 60 * 1;
      timer -= 1000 * 60 * 5;
    }
    return response.data.data.settings;
  } catch (error) {
    console.log(error);
  }
}


async function refreshToken() {
  refreshInterval = setInterval(async () => {
    const responseToken = await api.post('refreshtoken');
    localStorage.setItem('token', `Bearer ${responseToken.data.data.token}`);
  }, timer);
}

function stopRefresh() {
  clearInterval(refreshInterval);
}

const RefreshToken = () => {
  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(['settingsLogin'], () => requestTime(),
  {
    staleTime: 1000 * 60 * 10,
  });
};

export { refreshToken, stopRefresh, requestTime, RefreshToken };
