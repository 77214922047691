import { sentenceCase } from 'change-case';
import { useState, useRef } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
// componentes
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { LicenseListHead, LicenseListToolbar, LicenseMoreMenu } from '../components/tables/table-components/licenses';
import { StyledContainer } from '../components/styled-global';
import Loading from '../components/Loading';
import Page from '../components/Page';
import ModalLicenseExpirationDate from '../components/modal/modal-license/ModalLicenseExpirationDate';
import ModalLicenseStatus from '../components/modal/modal-license/ModalLicenseStatus';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// enum
import { LicenseStatus } from '../enum/LicenseStatusEnum';
// api
import api from '../service/api';

// ----------------------------------------------------------------------

export default function LicenseApp() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setisLoading] = useState(true);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('licencadatasolicitacao');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');

  // const [licencas, setLicencas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [licencaId, setLicencaId] = useState([]);
  const [editLicenses, setEditLicenses] = useState(undefined);
  const [confirmChangeStatusModalOpen, setConfirmChangeStatusModalOpen] = useState(false);
  const [confirmChangeValidateModalOpen, setConfirmChangeValidateModalOpen] = useState(false);

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const idFilter = useRef('licencadatasolicitacao');

  const { data: licencas } = useQuery(['licenca', page, rowsPerPage, idFilter.current, order, filterName], () =>
    getAllLicense(rowsPerPage, page, idFilter.current, order, filterName)
  );

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: 'licencanomecliente', label: t('licenses.table-header.client'), alignRight: false },
    { id: 'licencaversao', label: t('licenses.table-header.version'), alignRight: false },
    { id: 'licencachaveexecutavel', label: t('licenses.table-header.key'), alignRight: false },
    { id: 'licencadatasolicitacao', label: t('licenses.table-header.request'), alignRight: false },
    { id: 'licencadatavalidade', label: t('licenses.table-header.valitador'), alignRight: false },
    { id: 'licencadatastatus', label: t('licenses.table-header.date-status'), alignRight: false },
    { id: 'licencastatus', label: t('licenses.table-header.status'), alignRight: false },
    { id: '' },
    // { id: 'licencatxt', label: 'Lincença', alignRight: false },
  ];

  // ----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    idFilter.current = property;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = licencas?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - licencas?.length) : 0;

  const noLicenses = licencas?.length === 0;

  // eslint-disable-next-line no-unused-vars
  const handleFilterByAreaName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // todas as lincenças por paginacao
  const getAllLicense = async (rowsPerPage, page, idFilter, order, filterName) => {
    try {
      const response = await api.get(
        `/license/${rowsPerPage}/${rowsPerPage * page}/${idFilter}/${order}/${filterName}`
      );
      const manyLicense = response.data.data.licenses.licencas;
      const licenses = manyLicense.map((licenca) => ({
        idlicenca: licenca.idlicenca,
        licencachaveexecutavel: licenca.licencachaveexecutavel,
        licencadatasolicitacao: licenca?.licencadatasolicitacao,
        licencadatastatus: licenca?.licencadatastatus,
        licencadatavalidade: licenca?.licencadatavalidade,
        licencanomecliente: licenca.licencanomecliente,
        licencastatus: licenca.licencastatus === 0 ? 'desativado' : 'ativo',
        licencaversao: licenca.licencaversao,
      }));
      setLicencaId(licenses.idlicenca);
      return response.data.data.licenses;
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }

    return false;
  };

  // eslint-disable-next-line no-unused-vars
  const getLicense = async (licencaId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await api.get(`/license/${licencaId}/`);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  const postMutation = useMutation({
    mutationFn: (changeObject) => changeLicenseStatus(changeObject),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['licenca'] });
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    },
  });

  const postMutationDate = useMutation({
    mutationFn: (changeObject) => changeLicenseExpirationDate(changeObject),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['licenca'] });
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    },
  });

  // atualizar status da lincença
  async function changeLicenseStatus(changeObject) {
    const { licencaId, newLicenseStatus } = changeObject;
    try {
      // eslint-disable-next-line no-unused-vars
      const responseStatus = await api.put(`/license/${licencaId}`, {
        status: newLicenseStatus,
      });
    } catch (error) {
      console.log(error);
      toast.error(t('licenses.validate.error-status'));
    }
  }

  // atualizar data de validade da lincença
  async function changeLicenseExpirationDate(changeObject) {
    const { newDate, selectedLicense } = changeObject;
    try {
      // eslint-disable-next-line no-unused-vars
      const responseDate = await api.put(`/license/${selectedLicense}`, {
        date: newDate,
      });
    } catch (error) {
      console.log(error);
      toast.error(t('licenses.validate.error-date'));
    }
  }

  const confirmChangeStatusHandler = (selectedLicense, selectedStatus) => {
    let newStatus;
    if (selectedStatus === LicenseStatus.Ativo) {
      newStatus = 0;
    }
    if (selectedStatus === LicenseStatus.Inativo) {
      newStatus = 1;
    }
    const object = {
      licencaId: selectedLicense,
      newLicenseStatus: newStatus,
    };
    postMutation.mutate(object);
    setConfirmChangeStatusModalOpen(false);
  };

  const confirmChangeDateHandler = (selectedLicense, selectedDate) => {
    if (selectedDate !== null) {
      const newDate = new Date(selectedDate);
      const object = {
        newDate,
        selectedLicense,
      };
      postMutationDate.mutate(object);
      setConfirmChangeValidateModalOpen(false);
    }
  };

  const confirmChangeDateNullHandler = (selectedLicense, selectedDate) => {
    if (selectedDate === null) {
      const newDate = null;
      const object = {
        newDate,
        selectedLicense,
      };
      postMutationDate.mutate(object);
      setConfirmChangeValidateModalOpen(false);
    }
  };

  const openConfirmChangeStatusModal = (idlicenca) => {
    setLicencaId(idlicenca);
    setEditLicenses(...licencas.licencas.filter((licenca) => licenca.idlicenca === idlicenca));
    setConfirmChangeStatusModalOpen(true);
  };

  const openConfirmChangeDateModal = (idlicenca) => {
    setLicencaId(idlicenca);
    setEditLicenses(...licencas.licencas.filter((licenca) => licenca.idlicenca === idlicenca));
    setConfirmChangeValidateModalOpen(true);
  };

  return (
    <Page title={t('sidebar.license')}>
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} />
      <StyledContainer>
        <Loading loading={isLoading} />

        <ModalLicenseStatus
          isModalOpen={confirmChangeStatusModalOpen}
          setModalOpen={setConfirmChangeStatusModalOpen}
          itemToBeEdited={editLicenses}
          confirmButtonHandler={confirmChangeStatusHandler}
        />

        <ModalLicenseExpirationDate
          isModalOpen={confirmChangeValidateModalOpen}
          setModalOpen={setConfirmChangeValidateModalOpen}
          itemToBeEdited={editLicenses}
          confirmButtonHandler={confirmChangeDateHandler}
          confirmButtonNullHandler={confirmChangeDateNullHandler}
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} ml={1} mt={1}>
          <Typography variant="h4" gutterBottom>
            {t('licenses.title')}
          </Typography>
        </Stack>

        <Card>
          <LicenseListToolbar filterName={filterName} onFilterName={handleFilterByAreaName} />
          <Scrollbar>
            <TableContainer>
              <Table>
                <LicenseListHead
                  order={order}
                  orderBy={orderBy}
                  numSelected={selected.length}
                  headLabel={TABLE_HEAD}
                  rowCount={licencas?.total.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {licencas?.licencas.map((row) => {
                    const {
                      idlicenca,
                      licencachaveexecutavel,
                      licencadatasolicitacao,
                      licencadatastatus,
                      licencadatavalidade,
                      licencanomecliente,
                      licencastatus,
                      licencaversao,
                    } = row;
                    const isItemSelected = selected.indexOf(licencanomecliente) !== -1;

                    return (
                      <TableRow
                        hover
                        key={idlicenca}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">
                          <Typography>{licencanomecliente}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{licencaversao}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{licencachaveexecutavel}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{format(new Date(licencadatasolicitacao), 'dd/MM/yyyy')}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {licencadatavalidade !== null ? (
                            <Typography>{format(new Date(licencadatavalidade), 'dd/MM/yyyy')}</Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {licencadatastatus !== null ? (
                            <Typography>{format(new Date(licencadatastatus), 'dd/MM/yyyy')}</Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(licencastatus === LicenseStatus.Inativo && 'error') || 'success'}
                          >
                            {licencastatus === LicenseStatus.Inativo
                              ? sentenceCase(t('licenses.status-deactivate'))
                              : sentenceCase(t('licenses.status-activate'))}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <LicenseMoreMenu
                            status={licencastatus}
                            changeStatusFunction={() => openConfirmChangeStatusModal(idlicenca)}
                            openEditDateModal={() => openConfirmChangeDateModal(idlicenca)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {noLicenses && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography variant="p">Esta lista ainda não possui licenças</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Number(licencas?.total) ? Number(licencas?.total) : 5}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('licenses.page')}
          />
        </Card>
      </StyledContainer>
    </Page>
  );
}
