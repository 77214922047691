/* eslint-disable no-unused-vars */
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import { useTheme } from '@emotion/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import infoIcon from '@iconify/icons-mdi/information-variant';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Card, CircularProgress, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import styled from '@emotion/styled';
import searchFillIcon from '@iconify/icons-eva/search-fill';
import FormDialog from './modal/modal-config/OpenDialog';
import { ConfigContext } from '../pages/Config';
import { StyledContainer } from './styled-global/StyledGlobal';
import { usePermission } from '../store/usePermission';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 270,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 330, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ------------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  const t = stabilizedThis.map((el) => el[0]);

  return t;
}

const headCells = [
  {
    id: 'configsessao',
    numeric: false,
    disablePadding: false,
    label: i18next.t('config.table.session'),
  },
  {
    id: 'configchave',
    numeric: false,
    disablePadding: false,
    label: i18next.t('config.table.name'),
  },
  {
    id: 'configdescricao',
    numeric: false,
    disablePadding: false,
    label: i18next.t('config.table.description'),
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: false,
    label: i18next.t('config.table.value'),
  },
];

function EnhancedTableHead(props) {
  const theme = useTheme();

  // eslint-disable-next-line no-unused-vars
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ position: 'relative', borderBottom: `1px solid ${theme.palette.table_filler}` }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, filterConfig, setFilterConfig } = props;

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
        display: 'flex',
        justifyContent: 'space-between',
        gap: 5,
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterConfig}
          onChange={(event) => setFilterConfig(event.target.value)}
          placeholder={t('config.search-label')}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon={searchFillIcon} height={20} width={20} />
            </InputAdornment>
          }
          endAdornment={
            <Tooltip title={t('config.search-info')} disableInteractive>
              <InputAdornment position="start">
                <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            </Tooltip>
          }
        />
      )}
    </RootStyle>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ loading }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('sessao');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterConfig, setFilterConfig] = useState('');
  const configContext = useContext(ConfigContext);
  // eslint-disable-next-line no-unused-vars
  const { config, setConfig } = configContext;
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const settingsView = userPermissions.includes('settingsViewing');
      const settingsAdm = userPermissions.includes('settingsAdministrating');
      setPermissionView(settingsView);
      setPermissionAdmin(settingsAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = config.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      const filteredConfigs = array.filter(
        (config) => config.configchave.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      return filteredConfigs;
    }

    return stabilizedThis.map((el) => el[0]);
  }

  const noConfigs = config.length === 0;

  const filteredConfigs = applySortFilter(config, getComparator(order, orderBy), filterConfig);

  const isNotFound = !filteredConfigs.length && !!filterConfig;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - config.length) : 0;

  return (
    <StyledContainer>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2 }}>
        <Typography variant="h4" gutterBottom>
          {i18next.t('config.page-label')}
        </Typography>
      </Stack>
      <Card>
        <EnhancedTableToolbar
          numSelected={selected.length}
          filterConfig={filterConfig}
          setFilterConfig={setFilterConfig}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={config.length}
            />
            <TableBody sx={{ display: 'relative', height: 100 }}>
              {loading && (
                <TableRow
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '50%',
                    top: '60%',
                    transform: 'translate (50% 50%)',
                  }}
                >
                  <TableCell align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

              {stableSort(filteredConfigs, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow tabIndex={-1} key={row.idconfig}>
                      <TableCell component="th" id={labelId} scope="row" padding="normal">
                        {row.configsessao}
                      </TableCell>
                      <TableCell align="left">{row.configchave}</TableCell>
                      <TableCell align="left">{row.configdescricao}</TableCell>
                      <TableCell align="left">{row.configvalor}</TableCell>

                      <TableCell align="left">
                        <FormDialog
                          configchave={row.configchave}
                          configdescricao={row.configdescricao}
                          configtipo={row.configtipo}
                          configvalor={row.configvalor}
                          idconfig={row.idconfig}
                          sx={{ margin: 'auto 0' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        {i18next.t('projects.search-bar.not-found')}
                      </Typography>

                      <Typography variant="body2">
                        {i18next.t('projects.search-bar.not-found-text')} &nbsp;
                        <strong>&quot;{filterConfig}&quot;</strong>.
                        <br /> {i18next.t('projects.search-bar.not-found-text-complement')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {noConfigs && !loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Typography>{i18next.t('config.table.no-configs')}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={config.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={i18next.t('config.table.table-rows')}
        />
      </Card>
    </StyledContainer>
  );
}
