import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import { RegisterForm } from '../sections/auth/register';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  return (
    <Page title={t('sidebar.register')}>
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {t('register.title')}
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('register.subtitle')}</Typography>

            <RegisterForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
