import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, Typography, useMediaQuery,CardHeader  } from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import { fNumber } from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 280;
const LEGEND_HEIGHT = 70;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    // position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};


export default function AppCurrentVisits({ chartData,title, ...other }) {
  const [maxGraphHeight, setMaxGraphHeight] = useState(280);
  const down720pxHeight = useMediaQuery('(max-height: 720px)');

  const { t } = useTranslation();

  const colors = [];

  chartData.forEach((chart) => {
    colors.push(chart.color);
  });

  useEffect(() => {
    if (down720pxHeight) setMaxGraphHeight(200);
    else setMaxGraphHeight(340);
  }, [down720pxHeight]);

  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: !down720pxHeight ? { floating: true, horizontalAlign: 'center' } : { show: false },
    dataLabels: { enabled: true, textAnchor: 'middle', dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <ChartWrapperStyle dir="ltr" sx={{ height: maxGraphHeight }}>
      <CardHeader title={title} />
        {chartData.length <= 0 && (
          <Typography variant="h3" sx={{ textAlign: 'center', padding: '4rem' }}>
            {t('home.status.no-project')}
          </Typography>
        )}
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={220} />
      </ChartWrapperStyle>
    </Card>
  );
}
