import React, { useState, createContext, useEffect } from 'react';
import { filter } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
// material
import toast from 'react-hot-toast';
import {
  Card,
  Table,
  Stack,
  Button,
  Typography,
  TableContainer,
  TablePagination,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Paper,
} from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-fill';
// components
import Page from '../components/Page';
import { StyledContainer } from '../components/styled-global';
import { ID_SUPERUSER } from '../enum/SuperUser';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/tables/table-components/user';
import Loading from '../components/Loading';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// api
import api from '../service/api';
// store
import { usePermission } from '../store/usePermission';

// ----------------------------------------------------------------------

// Provider--------------------------------------------------------------
export const UsuarioContext = createContext();
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line no-unused-vars
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line no-unused-vars
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.usuarionome.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [usuario, setUsuario] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);

  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'usuarionome', label: t('user.name-label'), alignRight: false },
    { id: 'usuarioemail', label: 'Email', alignRight: false },
    { id: 'usuariostatus', label: 'Status', alignRight: false },
    { id: '' },
  ];

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const userView = userPermissions.includes('userViewing');
      const userAdm = userPermissions.includes('userAdministrating');
      setPermissionView(userView);
      setPermissionAdmin(userAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataUser = async () => {
    try {
      const response = await api.get(`/usersall`);
      setisLoading(false);
      setUsuario(response.data.data.users);
    } catch (error) {
      setisLoading(false);
      if (error.response.status === 401) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const checkStatus = (status) => {
    if (status === 0) {
      return t('user.disabled');
    }
    if (status === 1) {
      return t('user.active');
    }
    if (status === 2) {
      return t('user.blocked');
    }
  };
  useEffect(() => {
    fetchDataUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(usuario, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <Page title={t('sidebar.user')}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <StyledContainer>
        <Loading loading={isLoading} />
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {t('user.heading')}
          </Typography>
          {permissionAdmin === true ? (
            <Button variant="contained" component={RouterLink} to="/registernew" startIcon={<Icon icon={plusIcon} />}>
              {t('validation.user.new-user')}
            </Button>
          ) : (
            ''
          )}
        </Stack>
        <UsuarioContext.Provider value={{ usuario, setUsuario }}>
          <Card>
            <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={usuario.length}
                    onRequestSort={handleRequestSort}
                  />

                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
                    if (user.idusuario !== ID_SUPERUSER) {
                      return (
                        <TableBody key={user.idusuario}>
                          <TableRow>
                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={user.usuarionome} src={user.iconpath} />
                                <Typography variant="subtitle2" noWrap>
                                  {user.usuarionome}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{user.usuarioemail}</TableCell>
                            <TableCell align="left" color="success">
                              <Label variant="ghost" color={(user.usuariostatus === 1 && 'success') || 'error'}>
                                {checkStatus(user.usuariostatus)}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <UserMoreMenu id={user.idusuario} status={user.usuariostatus} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    }
                    return null;
                  })}
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              {t('projects.search-bar.not-found')}
                            </Typography>

                            <Typography variant="body2">
                              {t('projects.search-bar.not-found-text')} &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> {t('projects.search-bar.not-found-text-complement')}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('user.table-rows')}
              />
            </Scrollbar>
          </Card>
        </UsuarioContext.Provider>
      </StyledContainer>
    </Page>
  );
}
