import { useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import EnhancedTable from '../components/EnhancedTableHead';
import api from '../service/api';

// ----------------------------------------------------------------------

export const ConfigContext = createContext();

// ----------------------------------------------------------------------

export default function Config() {
  const [config, setConfig] = useState([]);
  const [open, setOpen] = useState(true);
  const [isLoading, setisLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    setisLoading(true);
    const fetchData = async () => {
      const response = await api.get('/configurations');
      setConfig(response.data.data.configurations);
      setisLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.configvalor]);

  // eslint-disable-next-line array-callback-return
  return (
    <Page title={t('sidebar.settings')}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <ConfigContext.Provider value={{ config, setConfig }}>
        <EnhancedTable loading={isLoading} />
      </ConfigContext.Provider>
    </Page>
  );
}
