/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
// protected routes
import ProtectedRoutes from './ProtectedRoutes';
import PerfilPermissionRoute from './PerfilPermissionRoute';
import PerfilPermissionRouteArea from './PerfilPermissionRouteArea';
import PerfilPermissionSuperUser from './PerfilPermissionSuperUser';
import Redirect from './Redirect';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// pages
import NotFound from '../pages/Page404';
import Login from '../pages/Login';
import RegisterNew from '../pages/RegisterNew';
import LicenseApp from '../pages/LicenseApp';
import ResetPassword from '../pages/ResetPassword';
import TwoFactor from '../pages/TwoFactor';
import NewPassword from '../pages/NewPassword';
import Config from '../pages/Config';
import Perfil from '../pages/Perfil';
import User from '../pages/User';
import Areas from '../pages/ManageAreas';
import ManageAccessProfile from '../pages/ManageAccessProfile';
import ManageAccessUser from '../pages/ManageAccessUser';
import Alerts from '../pages/Alerts';
import News from '../pages/News';
import Report from '../pages/Report';

// ----------------------------------------------------------------------

export default function Router() {
  const urlrelease = 'https://artwork-dev.notion.site/releases-b10b7faf9dee4ad9afeeb60bcb309cb8';

  return (
    <Routes>
      {/* arquivos externos */}
      <Route path="/release" element={<Redirect url={urlrelease} />} />
      {/* componente para verificar a autenticação */}
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="/" element={<Navigate to="/license/app" />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>

        <Route path="/license/app" element={<LicenseApp />} />

        <Route element={<PerfilPermissionRoute />}>
          <Route path="/registernew" element={<RegisterNew />} />
          <Route path="/user" element={<User />} />
          <Route path="/config" element={<Config />} />
          <Route path={'/manageruser'} element={<ManageAccessUser />} />
        </Route>
        <Route element={<PerfilPermissionSuperUser />}>
          <Route path={'/managerprofile'} element={<ManageAccessProfile />} />
        </Route>

        <Route element={<PerfilPermissionRouteArea />}>
          <Route path="/manageareas" element={<Areas />} />
        </Route>

        <Route path={`/perfil/:id`} element={<Perfil />} />

        <Route path={'/alerts'} element={<Alerts />} />
        <Route path={'/news'} element={<News />} />
        <Route path={'/report'} element={<Report />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/twofactor" element={<TwoFactor />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route path="/forgotpassword" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
