import { useState, useEffect, useRef } from 'react';
// router
import { Link } from 'react-router-dom';
// material
import {
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
  Tabs,
  Tab,
  Box,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import TabPanel, { a11yProps } from '../components/TabPanel';
import { StyledContainer } from '../components/styled-global/StyledGlobal';
//
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// store
import { userStore } from '../store/userStore';
// api
import api from '../service/api';
import { ID_SUPERUSER } from '../enum/SuperUser';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userList, user, theme) {
  return {
    fontWeight: user.indexOf(userList) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function ManageAccessUser() {
  const [isLoading, setisLoading] = useState(true);
  const [usersName, setUsersName] = useState([]);
  const [usersObject, setUsersObject] = useState([]);
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState(0);
  const [listRolesName, setRolesName] = useState([]);
  const [rolesChecked, setRolesChecked] = useState({});
  const [rolesObject, setRolesObject] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [valueTab, setValueTab] = useState(1);
  const UserId = useRef(null);
  // const getUserPermissions = userStore((state) => state.getUser);
  const theme = useTheme();
  const { t } = useTranslation();
  const prevRoleChecked = useRef();
  const getUserPermissions = userStore((state) => state.getUser);

  // async function getUser() {
  //   const userPermissions = await getUserPermissions();
  //   setRoles(userPermissions.roles);
  // }
  // useEffect(() => {
  //   getUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  async function requestApiUsers() {
    setisLoading(true);
    try {
      const responseUsers = await api.get('/users');
      // eslint-disable-next-line prefer-destructuring
      const users = responseUsers.data.data.users;
      setUsersObject(users);
      setisLoading(false);
      const listUser = users.map((usuario) => usuario.usuarionome);
      setUsersName(listUser);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  }

  async function getIdUser() {
    const userPermissions = await getUserPermissions();

    UserId.current = userPermissions.idusuario;
  }

  useEffect(() => {
    getIdUser();
  });

  async function requestApiRoles() {
    setisLoading(true);
    try {
      const responseRoles = await api.get('/perfis');
      // eslint-disable-next-line prefer-destructuring
      const roles = responseRoles.data.data.perfis;

      // Para fazer a tradução, setarei manualmente as três roles
      const listRoles = [t('manage-access.administrator'), t('manage-access.manager'), t('manage-access.common')];

      const listChecked = {};
      listRoles.forEach((role) => {
        listChecked[role] = false;
      });
      setRolesChecked(listChecked);
      setRolesName(listRoles);
      setRolesObject(roles);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    requestApiUsers();
    requestApiRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUsersName, setRolesName]);

  const handleChangeUser = async (event) => {
    const {
      target: { value },
    } = event;
    // eslint-disable-next-line arrow-body-style
    const user = usersObject.find((user) => {
      return user.usuarionome === value;
    });
    setUserId(user.idusuario);

    const responseUserRoles = await api.get(`/userperfis/${user.usuarioemail}`);
    const rolesApi = responseUserRoles.data.data.user.roles;

    const listChecked = {};
    listRolesName.forEach((nameRole) => {
      const exists = rolesApi.map((perfil) => perfil.perfilnome).includes(nameRole);
      if (exists) {
        listChecked[nameRole] = true;
        prevRoleChecked.current = nameRole;
      } else {
        listChecked[nameRole] = false;
      }
    });
    setRolesChecked(listChecked);

    setUser(typeof value === 'string' ? value : value);
  };

  const handleToggle = (value) => async (event) => {
    const perfil = rolesObject.find((perfil) => perfil.perfilnome === value);
    const listChecked = Object.keys(rolesChecked);
    const idsPerfilDelete = rolesObject.filter((role) => role.perfilnome !== value);

    listChecked.forEach(async (role) => {
      if (role === value && event.target.checked === true) {
        listChecked[value] = true;
        await api.post(`/users/${userId}`, {
          roles: [String(perfil.idperfil)],
        });
      } else {
        listChecked[role] = false;
      }

      setRolesChecked(listChecked);
    });

    idsPerfilDelete.forEach(async (perfilId) => {
      if (perfilId.perfilnome === prevRoleChecked.current) {
        await api.delete(`/users/${userId}`, {
          data: { roles: [String(perfilId.idperfil)] },
        });
      }
    });
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <Page title={t('sidebar.manageacess')}>
      <DashboardNavbar />
      <DashboardSidebar />
      <StyledContainer>
        {isLoading && (
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: '99',
            }}
          >
            <CircularProgress size="3rem" />
          </Box>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('manage-access.title')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
              {UserId?.current !== ID_SUPERUSER || UserId?.current === null ? (
                <></>
              ) : (
                <Tab component={Link} label={t('manage-access.tab1')} to="/managerprofile" {...a11yProps(0)} />
              )}
              <Tab component={Link} label={t('manage-access.tab2')} to="/manageruser" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={valueTab} index={1}>
            <Grid container spacing={3} sx={{ marginTop: '2%' }}>
              <Card sx={{ width: '100%' }}>
                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          {/* Usuário */}
                          <TableCell>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="user-acess-label">{t('manage-access.pleacehold-user')}</InputLabel>
                              <Select
                                labelId="user-acess-label"
                                id="user-acess"
                                value={user === '' ? '' : user}
                                onChange={handleChangeUser}
                                input={<OutlinedInput label="Usuário" />}
                                MenuProps={MenuProps}
                              >
                                {usersName.map((userList) => (
                                  <MenuItem key={userList} value={userList} style={getStyles(userList, user, theme)}>
                                    {userList}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>

                          {/* Role */}
                          <TableCell>
                            <List
                              dense
                              sx={{
                                width: '100%',
                                maxWidth: 460,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 200,
                              }}
                            >
                              {listRolesName.map((value) => {
                                const labelId = `checkbox-list-secondary-label-${value}`;
                                return (
                                  <ListItem
                                    key={value}
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={handleToggle(value)}
                                        checked={rolesChecked[value]}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    }
                                    disablePadding
                                  >
                                    <ListItemButton>
                                      <ListItemText id={labelId} primary={`${value}`} />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Grid>
          </TabPanel>
        </Box>
      </StyledContainer>
    </Page>
  );
}
