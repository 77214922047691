import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useQueries } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Link, Typography, Divider, IconButton, Avatar } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// hooks
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { Icon } from '@iconify/react';
import GoBackIcon from '@iconify/icons-eva/arrow-back-fill';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// sidebar
import { navConfig } from './NavConfig';
// components
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
// mock
import version from '../../_mock/version';
// api
import api from '../../service/api';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

const drawerWidth = 340;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  width: '30%',
  marginRight: '70%',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: '150%',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const GoBackButtonStyle = styled(IconButton)(() => ({
  width: 'max-content',
  margin: '10px auto 0px auto',
  borderRadius: 100,
  '&:hover': {
    cursor: 'pointer',
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export function reaload() {
  window.location.reload(false);
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, navigateBack }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const permissionedNavbar = useRef([]);
  // eslint-disable-next-line no-unused-vars
  const [stateForRender, setStateForRender] = useState(null);

  async function closeSideBard() {
    setStateForRender(0);
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }

  const user = JSON.parse(localStorage.getItem('User'));
  const userEmail = user?.useremail;

  const fetchData = async () => {
    const responsePerma = await api.get(`/userperfis/${userEmail}`);
    return responsePerma.data.data.user;
  };

  useEffect(() => {
    closeSideBard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fetchDataVersionBack = async () => {
    const response = await api.get(`/version`);
    return response.data.data;
  };

  const results = useQueries([
    { queryKey: ['versionBack'], queryFn: fetchDataVersionBack, staleTime: Infinity },
    {
      queryKey: ['account'],
      queryFn: () => fetchData(),
      enabled: !!userEmail,
      staleTime: Infinity,
    },
  ]);

  const sideBarPermission = async () => {
    permissionedNavbar.current = [...navConfig];
    const userPermissions = results[1].data;
    // const userPermissions = await getUserPermissions();

    const isAdmin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');

    const isGerente = userPermissions.roles.some((role) => role.perfilnome === 'Gerente');

    if (userPermissions !== null && userPermissions !== undefined) {
      if (!isAdmin && !isGerente) {
        const index = permissionedNavbar.current.findIndex((perm) => perm.path === '/manageareas');
        if (index >= 0) permissionedNavbar.current.splice(index, 1);
      }

      if (!isAdmin) {
        const index = permissionedNavbar.current.findIndex((perm) => perm.path === '/user');
        if (index >= 0) permissionedNavbar.current.splice(index, 1);
      }
      if (!isAdmin) {
        const index = permissionedNavbar.current.findIndex((perm) => perm.path === '/managerprofile');
        if (index >= 0) permissionedNavbar.current.splice(index, 1);
      }
      if (!isAdmin) {
        const index = permissionedNavbar.current.findIndex((perm) => perm.path === '/manageruser');
        if (index >= 0) permissionedNavbar.current.splice(index, 1);
      }
      if (!isAdmin) {
        const index = permissionedNavbar.current.findIndex((perm) => perm.path === '/config');
        if (index >= 0) permissionedNavbar.current.splice(index, 1);
      }
    }
  };

  useEffect(() => {
    sideBarPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results[1].data]);

  const renderContent = (
    <Scrollbar
      sx={{
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      }}
    >
      <GoBackButtonStyle
        sx={{
          opacity: !navigateBack && 0,
          pointerEvents: !navigateBack && 'none',
        }}
        onClick={() => navigate(navigateBack === true ? -1 : navigateBack)}
      >
        <Icon icon={GoBackIcon} fontSize={32} color={theme.palette.text.primary} />
      </GoBackButtonStyle>
      <Box sx={{ mb: 3, mx: 2.5, mt: 3 }}>
        <Link
          underline="none"
          component={RouterLink}
          to={`/perfil/${results[1].data?.idusuario}`}
          state={{ id: results[1].data?.idusuario }}
        >
          {open && (
            <AccountStyle>
              <Avatar src={results[1]?.data.iconpath} alt={results[1].data?.usuarionome} />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {results[1].data?.usuarionome}
                </Typography>
              </Box>
            </AccountStyle>
          )}
          {!open && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar alt={results[1].data?.usuarionome} src={results[1].data?.iconpath} />
            </Box>
          )}
        </Link>
      </Box>

      <NavSection navConfig={permissionedNavbar.current} isdraweropen={open} />

      {open && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {t('version')} Frontend: {version.number}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {t('version')} Backend: {results[0]?.data.version}
          </Typography>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <>
      <RootStyle>
        <Box>
          <AppBar open={open}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                position: 'absolute',
                top: 12,
                left: 24,
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              {open && (
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              )}
            </DrawerHeader>
            <Divider />
            {renderContent}
          </Drawer>
        </Box>
      </RootStyle>
    </>
  );
}
