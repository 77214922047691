// i18n
import i18next from 'i18next';
// icon
import { Icon } from '@iconify/react';
import licenseKey from '@iconify/icons-ic/round-vpn-key';
import areasIcon from '@iconify/icons-bx/area';
import usersIcon from '@iconify/icons-eva/people-fill';
import userAccountIcon from '@iconify/icons-mdi/account-box-multiple';
import settingsIcon from '@iconify/icons-eva/settings-2-fill';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: i18next.t('sidebar.license'),
    path: '/license/app',
    icon: getIcon(licenseKey),
  },
  {
    title: i18next.t('sidebar.areas'),
    path: '/manageareas',
    icon: getIcon(areasIcon),
  },
  {
    title: i18next.t('sidebar.user'),
    path: '/user',
    icon: getIcon(usersIcon),
  },
  {
    title: i18next.t('sidebar.manageacess'),
    path: '/manageruser',
    icon: getIcon(userAccountIcon),
  },
  {
    title: i18next.t('sidebar.report'),
    path: '/report',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: i18next.t('sidebar.settings'),
    path: '/config',
    icon: getIcon(settingsIcon),
  },
];

export { navConfig };
