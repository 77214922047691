import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { userStore } from '../store/userStore';



const PerfilPermissionRoute = () => {
  const [auth, setAuth] = useState(null);
const getUserPermissions = userStore((state) => state.getUser);

async function getIdUser() {
  const userPermissions = await getUserPermissions();
  setAuth(userPermissions.roles[0].perfilnome);
  // User.current = userPermissions;
}
  useEffect(() => {
    getIdUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return auth === null ? <> </> : auth ==="Administrador" ? <Outlet /> : <Navigate to="/" />;
};

export default PerfilPermissionRoute;
