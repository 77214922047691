import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import i18next from 'i18next';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function CalendarInput({
  setDate,
  date,
  error,
  setError,
  label = i18next.t('projects.new-project-form.conclusion-date-label'),
  disabled,
  dateReport,
}) {
  const handleChange = (newValue) => {
    setError('');
    setDate(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label={label}
          inputFormat={i18next.language === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
          disabled={disabled}
          disablePast={!dateReport}
          value={date}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} error={error !== '' && true} helperText={error} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
