import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// i18next
import { useTranslation } from 'react-i18next';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import moreIcon from '@iconify/icons-eva/more-vertical-fill';
import powerOffIcon from '@iconify/icons-bx/power-off';
import editCalendarRounded from '@iconify/icons-material-symbols/edit-calendar-rounded';
import { LicenseStatus } from '../../../../enum/LicenseStatusEnum';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function LicenseMoreMenu({ status, changeStatusFunction, openEditDateModal }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <IconButton sx={{ color: 'text.secondary' }} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreIcon} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={changeStatusFunction}>
          <ListItemIcon>
            <Icon icon={powerOffIcon} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={status === LicenseStatus.Inativo ? t('licenses.menu-activate') : t('licenses.menu-deactivate')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={openEditDateModal}>
          <ListItemIcon>
            <Icon icon={editCalendarRounded} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('licenses.menu-edit-validity')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
