import { useState, useEffect } from 'react';
// @mui
import { Box, Container, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// components
import Page from '../components/Page';
import ReportCard from '../components/ReportCard';
// icon
import auditTrail from '../assets/reports/audit-trail-search.png';
// store
import { userStore } from '../store/userStore';

// ----------------------------------------------------------------------

export default function Report() {
  const [open, setOpen] = useState(true);
  const [adm, setAdm] = useState(false);
  const { t } = useTranslation();
  const getUserPermissions = userStore((state) => state.getUser);

  const PerfilPermission = async () => {
    const userPermissions = await getUserPermissions();
    const isAdmin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setAdm(isAdmin);
  };

  useEffect(() => {
    PerfilPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={t('report.title')}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <Container sx={{ position: 'absolute', left: 60, minWidth: '90%', top: 95 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t('report.title')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'space-evenly',
            justifyContent: 'flex-start',
            gap: '1rem',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {adm === true ? (
            <Box sx={{ width: '20%' }}>
              <ReportCard
                title={t('reports.reportAuditTrailTitle')}
                description={t('reports.reportAuditTrailDescription')}
                icon={auditTrail}
                auditTrail
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Container>
    </Page>
  );
}
