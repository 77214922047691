import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-eva/eye-fill';
import eyeOffIcon from '@iconify/icons-eva/eye-off-fill';
import { FormProvider, RHFTextField, RHFTextFieldEmail } from '../../../components/hook-form';
// api
import api from '../../../service/api';
// refresh token
import { refreshToken } from '../../../utils/refreshToken';
// store
import { userStore } from '../../../store/userStore';

// ----------------------------------------------------------------------

export default function ReportForm({ handleClose, genaratorPDF }) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const getUser = userStore((state) => state.getUser);

  async function requestApiLogin(data) {
    try {
      const req = {
        email: data.email,
        password: data.password,
      };
      const userLoged = await getUser();
      if (req.email !== userLoged.usuarioemail) {
        toast.error(t('report.modal-report.user-invalid'));
        return;
      }
      const responseLogin = await api.post('/login', req);
      localStorage.setItem('token', `Bearer ${responseLogin.data.data.token}`);
      // eslint-disable-next-line dot-notation
      api.defaults.headers.common['Authorization'] = localStorage.getItem('token');
      const responseUser = await api.get(`/users/${req.email}`);
      const { user } = responseUser.data.data;
      const userStr = JSON.stringify({
        useremail: user.usuarioemail,
      });
      localStorage.setItem('User', userStr);
      localStorage.setItem('token', `Bearer ${responseLogin.data.data.token}`);
      refreshToken();
      genaratorPDF();
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }
  const emailRequired = t('validation.login.email-required');
  const emailInvalid = t('validation.login.email-validate');
  const passwordRequired = t('validation.login.password-required');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(emailInvalid).required(emailRequired),
    password: Yup.string().required(passwordRequired),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestApiLogin(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ width: '21em' }}>
        <RHFTextFieldEmail name="email" label={t('login.pleacehold-email')} InputLabelProps={{ shrink: true }} />

        <RHFTextField
          name="password"
          label={t('login.pleacehold-senha')}
          InputLabelProps={{ shrink: true }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 2 }}
        onClick={handleClose}
      >
        {t('authenticate')} 
      </LoadingButton>
    </FormProvider>
  );
}
