import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useQueryClient, useQuery } from 'react-query';
// refresh
import { stopRefresh } from '../utils/refreshToken';
// store
import { userStore } from '../store/userStore';
import { usePermission } from '../store/usePermission';
import api from '../service/api';

  // eslint-disable-next-line consistent-return
export default function LogoutTime() {
  const queryClient = useQueryClient();

  async function requesSettings() {
    try {
      const response = await api.get(`/settings/login`);
      return response.data.data.settings;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  }
  const { data:Cache } = useQuery(['settingsLogin'], () => requesSettings(),
  {
    staleTime: 1000 * 60 * 10,
  });

  const navigate = useNavigate();
  const sessionExpires = Cache ? Cache.isSessionExpires : false;
  const timeout = Cache ? 1000 * 60 * Cache.timeSessionExpires : 1000 * 60 * 60 * 5;
  const [remaining, setRemaining] = useState(timeout);
  const { t } = useTranslation();
  const setUserNull = userStore((state) => state.setUserNull);
  const setPremissionNull = usePermission((state) => state.setPermissionsNull);



  const handleIdle = async () => {
    if (sessionExpires === true && window.location.pathname !== '/login') {
      setUserNull();
      setPremissionNull()
      stopRefresh();
      queryClient.clear()
      queryClient.invalidateQueries()
      toast.error(`${t('logoutToast.toastLogout')}`, {
        duration: 20000,
      });
      toast.dismiss('timeExpired');
      navigate('/login', { replace: true });
      localStorage.clear();
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleAction = async (event) => {
    toast.dismiss('timeExpired');
  };

  // eslint-disable-next-line consistent-return
  const alertTime = (time) => {
    if (time < 1000 * 60 * 3 && window.location.pathname !== '/login') {
      return toast.error(`${t('logoutToast.toastAlert')}`, {
        duration: 1000 * 60 * 1,
        id: 'timeExpired',
        icon: <Icon icon="eva:clock-outline" width={40} height={40} />,
        position: 'bottom-right',
      });
    }
  };
  alertTime(remaining);

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleIdle,
    onAction: handleAction,
  });

  useEffect(() => {
    setRemaining(getRemainingTime);
    setInterval(() => {
      setRemaining(getRemainingTime);
    }, 1000 * 60);
  }, [getRemainingTime]);
}
