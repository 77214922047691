/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { Grid, Modal, Typography, TextField, Box, FormControl, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { compareTwoStrings } from 'string-similarity';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';

import api from '../../../../../service/api';

// -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
  position: 'relative',
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

const FormControlStyle = styled(FormControl)(() => ({
  display: 'flex',
  height: '90%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ButtonStyle = styled(Button)(() => ({
  width: '30%',
  border: '1px solid',
}));

const BoldTypographyStyle = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'bold',
}));

// ----------------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function AddAreaModal({ isModalOpen, setModalOpen, currentAreas, updateAreasArray }) {
  const [areaNameValue, setAreaNameValue] = useState('');

  const [similarNameWarning, setSimilarNameWarning] = useState(false);

  const [equalNameError, setEqualNameError] = useState(false);

  const [emptyNameError, setEmptyNameError] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { t } = useTranslation();

  const checkInputValidation = (inputValue) => {
    setEmptyNameError(false);
    const isAreaNameRepeated = currentAreas
      .map((area) => area.areanome)
      .map((area) => area.toLowerCase() === inputValue.toLowerCase())
      .includes(true);

    const isAreaNameSimilar = currentAreas
      .map((area) => area.areanome)
      .map((areanome) => compareTwoStrings(areanome.toLowerCase(), inputValue.toLowerCase()) > 0.8)
      .includes(true);

    setEqualNameError(isAreaNameRepeated);
    setSimilarNameWarning(isAreaNameSimilar);
  };

  const selectErrorMessage = () => {
    if (equalNameError) return t('areas.errors.already-has-name');
    if (similarNameWarning) return t('areas.errors.similar-name');
    if (emptyNameError) return t('areas.errors.no-name');
    return null;
  };

  const submitFormHandler = () => {
    if (areaNameValue.trim() === '') {
      setEmptyNameError(true);
      return;
    }
    if (equalNameError) return;
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    checkInputValidation(areaNameValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaNameValue]);

  const confirmButtonHandler = () => {
    createNewArea();
    setAreaNameValue('');
    setModalOpen(false);
    setOpenConfirmModal(false);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setAreaNameValue('');
  };

  async function createNewArea() {
    try {
      // eslint-disable-next-line no-unused-vars
      const responseCreateArea = await api.post('/areas', {
        areanome: areaNameValue,
      });
      updateAreasArray();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  return (
    <>
      <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
        <GridStyle>
          <Typography variant="h3" align="center">
            {t('areas.new-area-modal.title')}
          </Typography>
          <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
            <IconButton size="large" onClick={() => setModalOpen(false)}>
              <Icon icon={closeIcon} />
            </IconButton>
          </Box>
          <FormControlStyle>
            <InputBoxStyle>
              <TextField
                autoFocus
                error={equalNameError || emptyNameError}
                helperText={selectErrorMessage()}
                sx={{ width: '50%' }}
                id="outlined-basic"
                label={t('areas.new-area-modal.input-placeholder')}
                variant="outlined"
                color="success"
                value={areaNameValue}
                onChange={(event) => setAreaNameValue(event.target.value)}
              />
            </InputBoxStyle>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <Button onClick={submitFormHandler} sx={{ width: '20%' }} variant="contained">
                {t('areas.new-area-modal.create-area-button')}
              </Button>
            </Box>
          </FormControlStyle>
        </GridStyle>
      </ModalStyle>
      <ModalStyle open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 10, maxWidth: '90%' }}>
            <Typography variant="p" fontSize={30} textAlign={'center'}>
              {t('areas.new-area-modal.confirm-modal.title')} <BoldTypographyStyle>{areaNameValue}</BoldTypographyStyle>
              ?
            </Typography>
            <InputBoxStyle>
              <ButtonStyle variant="contained" sx={{ border: 'none' }} onClick={confirmButtonHandler}>
                {t('areas.new-area-modal.confirm-modal.confirm-button')}
              </ButtonStyle>
              <ButtonStyle onClick={() => setOpenConfirmModal(false)}>
                {t('areas.new-area-modal.confirm-modal.decline-button')}
              </ButtonStyle>
            </InputBoxStyle>
          </InputBoxStyle>
        </GridStyle>
      </ModalStyle>
    </>
  );
}
