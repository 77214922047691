/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, TextField, Box, FormControl } from '@mui/material';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { compareTwoStrings } from 'string-similarity';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';
import { StyledBoldTypography, StyledButton } from '../../../../styled-global';

// Estilos -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

const FormControlStyle = styled(FormControl)(() => ({
  display: 'flex',
  height: '90%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

// ---------------------------------------------------------------------------

export default function EditAreasModal({
  isModalOpen,
  setModalOpen,
  areaToBeEdited,
  areaId,
  editAreaNameFunction,
  editGroupAreaFunction,
  currentAreas,
}) {
  const [areaNameValue, setAreaNameValue] = useState(areaToBeEdited?.areanome);
  const [areaGroupValue, setAreaGroupValue] = useState(areaToBeEdited?.areaagrupamento);
  const [similarNameWarning, setSimilarNameWarning] = useState(false);
  const [equalNameError, setEqualNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { t } = useTranslation();

  const checkInputValidation = (inputValue) => {
    setEmptyNameError(false);

    const filteredAreasToBeEdited = currentAreas.filter((area) => area.idarea !== areaToBeEdited.idarea);

    const isAreaNameRepeated = filteredAreasToBeEdited
      .map((area) => area.areanome)
      .map((area) => area?.toLowerCase() === inputValue?.toLowerCase())
      .includes(true);

    const isAreaNameSimilar = filteredAreasToBeEdited
      .map((area) => area.areanome)
      .map((areanome) => compareTwoStrings(areanome?.toLowerCase(), inputValue?.toLowerCase()) > 0.8)
      .includes(true);

    setEqualNameError(isAreaNameRepeated);
    setSimilarNameWarning(isAreaNameSimilar);
  };

  const selectErrorMessage = () => {
    if (equalNameError) return t('areas.errors.already-has-name');
    if (similarNameWarning) return t('areas.errors.similar-name');
    if (emptyNameError) return t('areas.errors.no-name');
    return null;
  };

  useEffect(() => {
    checkInputValidation(areaNameValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaNameValue]);

  // Atualizar o valor do Input
  useEffect(() => {
    setAreaNameValue(areaToBeEdited?.areanome);
    setAreaGroupValue(areaToBeEdited?.areaagrupamento);
  }, [areaToBeEdited]);

  const applyNameButtonHandler = () => {
    if (areaNameValue?.trim() === '') {
      setEmptyNameError(true);
      return;
    }
    if (areaGroupValue?.trim() === '') {
      setEmptyNameError(true);
      return;
    }
    if (equalNameError) return;
    setOpenConfirmModal(true);
  };

  const confirmButtonHandler = () => {
    if (areaToBeEdited?.areanome) editAreaNameFunction(areaNameValue, areaId);
    if (areaToBeEdited?.areaagrupamento) editGroupAreaFunction(areaGroupValue, areaId);
    setModalOpen(false);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <ModalStyle
        open={isModalOpen}
        onClose={() => {
          setAreaNameValue('');
          setAreaGroupValue('');
          setEmptyNameError(false);
          setModalOpen(false);
        }}
      >
        <GridStyle sx={{ position: 'relative' }}>
          <Typography variant="h3" align="center">
            {t('areas.edit-area-modal.title')}
          </Typography>
          <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
            <IconButton size="large" onClick={() => setModalOpen(false)}>
              <Icon icon={closeIcon} />
            </IconButton>
          </Box>
          <FormControlStyle>
            {/* nome */}
            <InputBoxStyle sx={{ gap: 2, m: 2 }}>
              <TextField
                autoFocus
                error={equalNameError || emptyNameError}
                helperText={selectErrorMessage()}
                sx={{ width: '50%' }}
                id="outlined-basic"
                label={'Nome da Área'}
                variant="outlined"
                color="success"
                value={areaNameValue || ''}
                onChange={(event) => setAreaNameValue(event.target.value)}
              />
            </InputBoxStyle>
            {/* agrupamento */}
            <InputBoxStyle sx={{ gap: 2, m: 2 }}>
              <TextField
                autoFocus
                error={equalNameError || emptyNameError}
                helperText={selectErrorMessage()}
                sx={{ width: '50%' }}
                id="outlined-basic"
                label={'Agrupamento de Área'}
                variant="outlined"
                color="success"
                value={areaGroupValue || ''}
                onChange={(event) => setAreaGroupValue(event.target.value)}
              />
            </InputBoxStyle>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <StyledButton contained onClick={applyNameButtonHandler}>
                {t('areas.edit-area-modal.apply-button')}
              </StyledButton>
            </Box>
          </FormControlStyle>
        </GridStyle>
      </ModalStyle>
      <ModalStyle
        open={openConfirmModal}
        onClose={() => {
          setEmptyNameError(false);
          setOpenConfirmModal(false);
        }}
      >
        <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 10, maxWidth: '90%' }}>
            <Typography variant="p" fontSize={30} textAlign={'center'}>
              {/* {t('areas.edit-area-modal.confirm-modal.title')}{' '} */}
              {'Deseja editar a Área de'} <StyledBoldTypography>{areaToBeEdited?.area}</StyledBoldTypography>{' '}
              {/* {t('areas.edit-area-modal.confirm-modal.to')} */}
              <StyledBoldTypography>{areaNameValue}</StyledBoldTypography>?
            </Typography>
            <InputBoxStyle>
              <StyledButton contained onClick={confirmButtonHandler}>
                {t('areas.edit-area-modal.confirm-modal.confirm-button')}
              </StyledButton>
              <StyledButton onClick={() => setOpenConfirmModal(false)}>
                {t('areas.edit-area-modal.confirm-modal.decline-button')}
              </StyledButton>
            </InputBoxStyle>
          </InputBoxStyle>
        </GridStyle>
      </ModalStyle>
    </>
  );
}
