import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/padlock.png';

// ----------------------------------------------------------------------

const ImgBoxStyle = styled(Box)(() => ({
  width: 80,
  margin: 2,
  padding: 10,
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
export default function Logo({ sx }) {
  return (
    <Link to="/">
      <ImgBoxStyle component="img" src={logo} />
    </Link>
  );
}

Logo.propTypes = {
  sx: PropTypes.any,
};
