import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-eva/eye-fill';
import eyeOffIcon from '@iconify/icons-eva/eye-off-fill';
import { ID_SUPERUSER } from '../../../enum/SuperUser';
import { FormProvider, RHFTextField, RHFCheckbox, RHFTextFieldEmail } from '../../../components/hook-form';
// api
import api from '../../../service/api';
import validations from '../../../service/validations';
// refresh token
import { refreshToken } from '../../../utils/refreshToken';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [login2Factor, setLogin2Factor] = useState();
  const [loginAttempt, setLoginAttempt] = useState();
  // cokies
  // eslint-disable-next-line no-unused-vars
  const [cookieCheck, setCookieCheck, removeCookieCheck] = useCookies(false);
  const [cookies, setCookie, removeCookie] = useCookies(undefined);
  //
  const { t } = useTranslation();

  useEffect(() => {
    async function settings() {
      const settings = await validations('login');
      setLogin2Factor(settings.login2Factor);
      setLoginAttempt(settings.loginAttempt);
    }
    settings();
  }, []);

  async function requestApiLogin(data) {
    const emaillowercase = data.email.toLowerCase();
    try {
      const req = {
        email: emaillowercase,
        password: data.password,
      };
      const responseLogin = await api.post('/login', req);
      localStorage.setItem('tokenTemporary', `Bearer ${responseLogin.data.data.token}`);
      // eslint-disable-next-line dot-notation
      api.defaults.headers.common['Authorization'] = localStorage.getItem('tokenTemporary');
      const responseUser = await api.get(`/users/${req.email}`);
      const { user } = responseUser.data.data;
      const userStr = JSON.stringify({
        // iduser: user.idusuario,
        // username: user.usuarionome,
        useremail: user.usuarioemail,
      });

      localStorage.setItem('User', userStr);
      if (responseLogin.data.data.firstLogin) {
        navigate('/newpassword', { replace: true, state: { email: req.email } });
      } else if (user.idusuario === ID_SUPERUSER) {
        localStorage.setItem('token', `Bearer ${responseLogin.data.data.token}`);
        refreshToken();
        navigate('/license/app', { replace: true });
      } else if (login2Factor) {
        navigate('/twofactor', { replace: true, state: { password: req.password, email: req.email } });
      } else {
        localStorage.setItem('tokenTemporary', '');
        localStorage.setItem('token', `Bearer ${responseLogin.data.data.token}`);
        refreshToken();
        navigate('/license/app', { replace: true });
      }

      // cokies active
      if (data.remember === true) {
        setCookie('mail', data.email);
        setCookieCheck('check', data.remember);
      }
      if (data.remember === false) {
        removeCookie('mail', data.email);
        removeCookieCheck('check', data.remember);
      }
      //
    } catch (error) {
      if (error.response.status === 401) {
        setLoginAttempt(loginAttempt - 1);
        if (loginAttempt === 0) {
          toast.error(`${t('login.alert-block')}`);
        } else if (loginAttempt > 0) {
          toast.error(`${t('login.alert-attempt')} ${loginAttempt}`);
        }
        toast.error(`${error.response.data.data.errors[0]}`);
      } else if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const emailRequired = t('validation.login.email-required');
  const emailInvalid = t('validation.login.email-validate');
  const passwordRequired = t('validation.login.password-required');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(emailInvalid).required(emailRequired),
    password: Yup.string().required(passwordRequired),
  });

  const defaultValues = {
    email: cookies.mail ? cookies.mail : '',
    password: '',
    remember: cookies.check === 'true',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestApiLogin(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextFieldEmail name="email" label={t('login.pleacehold-email')} InputLabelProps={{ shrink: true }} />

        <RHFTextField
          name="password"
          label={t('login.pleacehold-senha')}
          InputLabelProps={{ shrink: true }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={t('login.lembre')} />

        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forgotpassword">
          {t('login.forgotpassword')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('login.button-login')}
      </LoadingButton>
    </FormProvider>
  );
}
