// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, CardMedia, Box, Grid } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { NewPasswordForm } from '../sections/auth/newpassword';
// images
import Background from '../assets/logos-login/fundolicenca.jpg';
import BackgroundFlow from '../assets/logos-login/flow.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height: 600,
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  backgroundColor: '#fff',
}));

const stylesBackground = {
  display: 'flex',
  justifyContent: 'center',
  zIndex: -1,
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
};

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'md');
  const { t } = useTranslation();

  return (
    <Page title="Login">
      <Grid sx={stylesBackground} alt="login">
        <RootStyle>
          {smUp && (
            <Box sx={{ px: 15, mt: 0, mb: 0, height: 700, width: 900 }}>
              <Typography variant="h3" sx={{ color: 'text.logintitle' }}>
                Artwork Management
              </Typography>
              <CardMedia component="img" image={BackgroundFlow} />
            </Box>
          )}

          <SectionStyle>
            <Container maxWidth="sm">
              <ContentStyle>
                <Logo />
                <Typography variant="h4" gutterBottom>
                  {t('newpassword.subtitle')}
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('newpassword.body')}</Typography>

                <NewPasswordForm />
              </ContentStyle>
            </Container>
          </SectionStyle>
        </RootStyle>
      </Grid>
    </Page>
  );
}
