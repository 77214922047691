import { format, formatDistanceToNow } from 'date-fns';
import { ptBR, enUS, es } from 'date-fns/locale';
import i18next from 'i18next';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  let locale;
  if (i18next.language === 'pt') locale = ptBR;
  if (i18next.language === 'en') locale = enUS;
  if (i18next.language === 'es') locale = es;
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale,
  });
}
