import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import api from '../service/api';

// modal
import ModalReportAuditTrial from './modal/modal-report/ModalReportAuditTrial';

const ImgBoxStyle = styled(Box)(() => ({
  width: 36,
  height: 36,
}));

export default function ReportCard({ title, description, icon, auditTrail }) {
  const [auditTrailOpen, setAuditTrailReportOpen] = useState(false);

  const handleClickOpen = () => {
    if (auditTrail) setAuditTrailReportOpen(true);
  };

  const handleClose = () => {
    if (auditTrail) setAuditTrailReportOpen(false);
  };

  // eslint-disable-next-line consistent-return
  const getUsers = async () => {
    try {
      const response = await api.get(`/users`);
      return response.data.data.users;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(['usersForReport'], () => getUsers(), {
    refetchOnWindowFocus: true,
  });

  return (
    <Box sx={{ height: '100%' }}>
      <Card sx={{ width: '100%', height: '100%' }}>
        <CardActionArea onClick={handleClickOpen} sx={{ height: '100%' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1, marginTop: -0.3 }}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                paddingX: 1,
                marginBottom: 0,
              }}
            >
              <ImgBoxStyle component="img" src={icon} sx={{ alignSelf: 'flex-start', marginTop: 0.6 }} />
              <Typography component="span" variant="h5" sx={{ width: '100%' }}>
                {title}
              </Typography>
            </Box>
            <Typography component="span" variant="body2" color="text.secondary" sx={{ padding: 1 }}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        {/* Audit Trail */}
        <ModalReportAuditTrial
          open={auditTrailOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />
      </Card>
    </Box>
  );
}

ReportCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
};
