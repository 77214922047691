// material
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Logo from '../../components/Logo';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { DarkModeContext } from '../../theme/index';
// import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  zIndex: 1,
  width: '100%',

  boxShadow: 'none',
  backgroundColor: 'transparent',
  // backgroundColor: alpha(theme.palette.background.default, 0.4),
  [theme.breakpoints.up('lx')]: {
    width: `calc(100% - 65px)`,
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    marginRight: '15%',
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(100% - 65px)`,
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    marginRight: '25%',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  justifyContent: 'flex-end',
  minHeight: APPBAR_DESKTOP,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const [showLogo, setShowLogo] = useState(true);
  const darkModeContext = useContext(DarkModeContext);
  const { darkMode, setdarkMode } = darkModeContext;

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition < 50) {
      setShowLogo(true);
    }
    if (scrollPosition > 50) {
      setShowLogo(false);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <RootStyle>
      <ToolbarStyle>
        {showLogo && (
          <Box
            sx={{ display: 'inline-flex', position: 'absolute', left: '150px', top: 0, transform: 'translateX(-50%)' }}
          >
            <Logo />
          </Box>
        )}
        <Stack direction="row" alignItems="center" gap={2}>
          <LanguagePopover />
          <IconButton
            sx={{ color: 'text.secondary', padding: 0, marginRight: 1 }}
            onClick={() => {
              setdarkMode(!darkMode);
              localStorage.setItem('darkMode', Boolean(!darkMode));
            }}
          >
            {darkMode === true ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
