// routes
import { Toaster } from 'react-hot-toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { CurrentModuleProvider } from './store/CurrentModuleContext';

// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <CurrentModuleProvider>
            <ScrollToTop />
            <Toaster
              containerStyle={{
                position: 'absolute',
                left: '80%',
              }}
            />
            <BaseOptionChartStyle />
            <Router />
          </CurrentModuleProvider>
        </DndProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
