import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledBoldTypography } from '../../styled-global';
import { LicenseStatus } from '../../../enum/LicenseStatusEnum';

//------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '18rem',
  width: '23rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '15px',
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

//--------------------------------------------------------------------

export default function ModalLicenseStatus({ isModalOpen, setModalOpen, confirmButtonHandler, itemToBeEdited }) {
  const { t } = useTranslation();
  return (
    <ModalStyle open={isModalOpen} onClose={() => setModalOpen(false)}>
      <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 8, maxWidth: '90%', flex: 1 }}>
          <Typography variant="p" fontSize={23} textAlign={'center'} sx={{ margin: 2 }}>
            {t('licenses.modal-status.body-one')}{' '}
            <StyledBoldTypography>
              {itemToBeEdited?.licencastatus === LicenseStatus.Ativo
                ? t('licenses.modal-status.deactivate')
                : t('licenses.modal-status.activate')}
            </StyledBoldTypography>{' '}
            {t('licenses.modal-status.body-two')}
          </Typography>
          <InputBoxStyle>
            <Button
              variant="contained"
              color="success"
              onClick={() => confirmButtonHandler(itemToBeEdited.idlicenca, itemToBeEdited.licencastatus)}
            >
              {t('licenses.modal-status.button-confirm')}
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              {t('licenses.modal-status.button-cancel')}
            </Button>
            <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%' }}>
              <IconButton size="large" onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </InputBoxStyle>
        </InputBoxStyle>
      </GridStyle>
    </ModalStyle>
  );
}
