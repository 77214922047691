import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  // Tooltip, IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import infoIcon from '@iconify/icons-mdi/information-variant';

import { useTranslation } from 'react-i18next';
// component
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-eva/search-fill';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName }) {
  const { t } = useTranslation();
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={t('validation.user.search-label')}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon={searchIcon} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
          endAdornment={
            <Tooltip title={t('validation.user.search-info')} disableInteractive>
              <InputAdornment position="start">
                <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            </Tooltip>
          }
        />
      )}
    </RootStyle>
  );
}
