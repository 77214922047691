// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
// sections
import { TwoFactorForm } from '../sections/auth/twofactor';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const TextHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

// ----------------------------------------------------------------------

export default function TwoFactor() {
  const { t } = useTranslation();
  return (
    <Page title="Two Factor Authentication">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <TextHeader>
              <Typography variant="h4" gutterBottom>
                {t('twofactor.title')}
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('twofactor.body')}</Typography>
            </TextHeader>

            <TwoFactorForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
