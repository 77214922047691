import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Box, Button, IconButton, Stack, Typography, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarInput from '../../CalendarInput';

//------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '20rem',
  width: '25rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '15px',
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

//--------------------------------------------------------------------

export default function ModalLicenseExpirationDate({
  isModalOpen,
  setModalOpen,
  confirmButtonHandler,
  confirmButtonNullHandler,
  itemToBeEdited,
}) {
  const [date, setDate] = useState(null);
  const [dateError, setDateError] = useState('');

  const { t } = useTranslation();

  return (
    <ModalStyle open={isModalOpen} onClose={() => setModalOpen(false)}>
      <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 4, maxWidth: '70%', flex: 1 }}>
          <DialogTitle component="span" sx={{ display: 'flex', flexDirection: 'row', alignSelf: '' }}>
            <Typography
              component="span"
              variant="p"
              fontSize={23}
              textAlign={'center'}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 3 }}
            >
              {t('licenses.modal-edit-validity.title')}
            </Typography>
          </DialogTitle>
          <Stack component="span" direction="row" alignItems="center" sx={{ marginLeft: 1 }}>
            <CalendarInput
              setDate={setDate}
              date={date}
              error={dateError}
              setError={setDateError}
              dateReport
              label={t('licenses.modal-edit-validity.label')}
            />
          </Stack>
          <InputBoxStyle>
            <Button
              variant="contained"
              color="success"
              onClick={() => confirmButtonHandler(itemToBeEdited.idlicenca, date)}
            >
              {t('licenses.modal-edit-validity.button-confirm')}
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              {t('licenses.modal-edit-validity.button-cancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                confirmButtonNullHandler(itemToBeEdited.idlicenca, null);
              }}
            >
              {t('licenses.modal-edit-validity.button-remove')}
            </Button>
            <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%' }}>
              <IconButton size="large" onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </InputBoxStyle>
        </InputBoxStyle>
      </GridStyle>
    </ModalStyle>
  );
}
