import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import ManageAreasTable from '../components/tables/ManageAreasTable';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';

// ----------------------------------------------------------------------

export default function ManageAreas() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Page title={t('sidebar.areas')}>
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} />
      <ManageAreasTable />
    </Page>
  );
}
